import React from "react"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import get from "lodash/get"
import Layout from "../components/layout"
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa"
import { Row, Col } from "react-bootstrap"
import Bio from "../components/bio"
import Img from "gatsby-image";
const BlogPostContentfulTemplate =(props)=> {
    const post = get(props, "data.contentfulBlog")
    const siteTitle = get(props, "data.site.siteMetadata.title")
    const previous = props.pageContext.previous;
    const next = props.pageContext.next;
    return (
      <Layout>
        <div>
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <div>
            <h1 className="mb-1">{post.title}</h1>
              <small className="text-muted">{new Date(post.createdAt).toDateString()}</small>
            <Img fluid={post.image.fluid} alt={post.title} className="mt-3" />
            <div
              className="mt-3"
              dangerouslySetInnerHTML={{
                __html: post.content.childContentfulRichText.html,
              }}
            />
            {(previous || next) && <hr />}
            <Bio />
            <Row className="my-4">
              <Col>
                {previous && (
                  <span className="d-flex flex-column align-items-start">
                    <p className="mb-0 ml-4">
                      <small>Previous</small>
                    </p>
                    <Link to={`blog/${previous.slug}`} rel="prev">
                      <FaLongArrowAltLeft className="mr-2" /> {previous.title}
                    </Link>
                  </span>
                )}
              </Col>
              <Col>
                {next && (
                  <span className="d-flex flex-column align-items-end">
                    <p className="mb-0">
                      <small>Next</small>
                    </p>
                    <Link to={`blog/${next.slug}`} rel="prev">
                      {next.title}
                      <FaLongArrowAltRight className="ml-2" />
                    </Link>
                  </span>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    )
}

export default BlogPostContentfulTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlog(slug: { eq: $slug }) {
      title
      createdAt
      content {
        childContentfulRichText {
          html
        }
      }
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
